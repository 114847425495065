import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet";

interface SeoProps {
  description?: string;
  title?: string;
  imageUrl?: string;
  imageAlt?: string;
}

const SEO: FunctionComponent<SeoProps> = ({ title, description, imageUrl, imageAlt }) => {
  const metaDescription = description || "Rexona";
  const siteTitle = title ? title + " | Rexona" : "Rexona";
  const twitterImage = imageUrl || metaDescription;
  const twitterImageAlt = imageAlt || metaDescription;

  return (
    <Helmet>
      <title>{siteTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={siteTitle} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={siteTitle} />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:image" content={twitterImage} />
      <meta name="twitter:image:alt" content={twitterImageAlt} />
      <meta name="twitter:description" content={metaDescription} />
    </Helmet>
  );
};

export default SEO;
